<template>
  <div class="laboratory">
    <div class="title">
      <span>实验室信息 /</span><span> PRODUCTS</span>
      <i></i>
    </div>
    <div class="scrollBox">
      <vue-seamless-scroll
        :data="dataArr"
        :class-option="initOption"
        style="height: 220px; overflow: hidden"
      >
        <ul>
          <li v-for="(item, index) in dataArr" :key="index">
            <div class="bord">
              <span></span>
              <span></span>
              <span></span>
              <span></span> 
              <span></span>
              <span></span>
              <span></span> 
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div class="center">
              <img :src="item.imgurl" alt="">
            </div>
            <div class="bord">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </li>
        </ul>
      </vue-seamless-scroll>
    </div>
    <div class="more">更多+</div>
  </div>
</template> 
    
<script>
export default {
  data() {
    return {
      dataArr: [
        { imgurl:require("../assets/image/byd1.jpg") },
        { imgurl:require("../assets/image/byd2.jpg") },
        { imgurl:require("../assets/image/byd3.jpg") },
        { imgurl:require("../assets/image/byd4.jpg") },
        { imgurl:require("../assets/image/byd5.jpg") },
        { imgurl:require("../assets/image/byd6.jpg") },
        { imgurl:require("../assets/image/byd7.jpg") },
        
      ],
    };
  },
  computed: {
    initOption() {
      return {
        step: 1, // 数值越大速度滚动越快
        limitMoveNum: 4, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 2, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  methods: {},

  created() {},

  mounted() {},

  components: {},
};
</script>
<style lang="less">

.scrollBox{
  margin: 0 auto;
  margin-top: 60px;
  width: 1920px;
  height: 220px;
  padding-top: 12px;
  padding-bottom:12px;
  box-sizing: border-box;
  background-color: #000000;
  ul{
    height: 220px;
    display: flex;
    li{
      width: 196px;
      height: 200px; 
      cursor: pointer;
      .bord{
        display: flex;
        justify-content: space-around;
        span{
          display: inline-block;
          width: 10px;
          height: 13px;
          background-color: #ffffff;
        }
      }
      .center{
        width: 196px;
        height: 135px;
        margin-top: 12px;
        margin-bottom: 12px;
        background-color: #000000;
        img{
          width: 187px;
          height: 100%;
        }
      }
    }
  }
}
</style> 